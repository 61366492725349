import styled from 'styled-components';

export const NotFoundSection = styled.section`
    width:100%;
`;
export const NotFoundSectionInner = styled.div`
    margin:80px auto;
    max-width:600px;
    text-align:center;
    padding:25px;
`;
export const NotFoundImg = styled.img`
    max-height: 230px;
    margin-bottom:20px;
`;
export const NotFoundHeading = styled.h3`
    margin-bottom:20px;
    font-size: 50px;
`;
export const NotFoundPara = styled.p`
    margin-bottom:40px;
`;
export const NotFoundBtnBg = styled.div`

`;
