import styled from 'styled-components';

export const CommonBtn= styled.a`
    background: #ff9900;
    border-radius: 3px;
    color: #fff;
    padding: 8px 15px;
    text-transform: uppercase;
    text-decoration: none;
    display: block;
    line-height: 30px;
    max-width: 250px;
    margin: auto;
    :hover{
        background:#e88d04;
    }
`;

export const Commonpara = styled.p`
    color:#5c5c5c;
    margin-bottom:26px;
    font-size:16px;
`;

export const Commonh3 = styled.h3`
    font-size: 36px;
    margin-bottom: 30px;
`;

export const Commonh4 = styled.h4`
    font-size:24px;
    color:#202020;
    line-height:30px;
    margin-bottom:20px
`;

export const SectionHeading = styled.h3`
    font-size:30px;
    color:#202020;
    margin-bottom:15px;
    text-align:center;
    font-weight:300;
`;


export const SectionSubHeading = styled.p`
    font-size:16px;
    color:#5c5c5c;
    text-align:center;
    font-weight:300;
`;


